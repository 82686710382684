export const UPDATE_HELP = 'UPDATE_HELP';
export const UPDATE_STEP = 'UPDATE_STEP';
export const UPDATE_FROM_LANG = 'UPDATE_FROM_LANG';
export const UPDATE_TO_LANG = 'UPDATE_TO_LANG';
export const UPDATE_USER_FILE = 'UPDATE_USER_FILE';
export const UPDATE_USER_TEXT = 'UPDATE_USER_TEXT';

export const updateHelp = help => async dispatch => {
  dispatch({
    type: UPDATE_HELP,
    payload: help,
  });
};

export const updateStep = step => async dispatch => {
  dispatch({
    type: UPDATE_STEP,
    payload: step,
  });
};

export const updateFromLanguage = step => async dispatch => {
  dispatch({
    type: UPDATE_FROM_LANG,
    payload: step,
  });
};

export const updateToLanguage = step => async dispatch => {
  dispatch({
    type: UPDATE_TO_LANG,
    payload: step,
  });
};

export const updateUserFile = step => async dispatch => {
  dispatch({
    type: UPDATE_USER_FILE,
    payload: step,
  });
};

export const updateUserText = step => async dispatch => {
  dispatch({
    type: UPDATE_USER_TEXT,
    payload: step,
  });
};

import {
  UPDATE_HELP,
  UPDATE_STEP,
  UPDATE_FROM_LANG,
  UPDATE_TO_LANG,
  UPDATE_USER_FILE,
  UPDATE_USER_TEXT,
} from '../actions/userProgress';

const initialState = {
  completed: [],
  current: 1,
  help: 'Please select input and output language',
  fromLanguage: null,
  toLanguage: null,
  userFile: null,
  userText: null,
};
export default (state = initialState, action) => {
  const newState = { ...state };
  switch (action.type) {
    case UPDATE_HELP:
      newState.help = action.payload;
      break;
    case UPDATE_STEP:
      newState.current = action.payload;
      newState.completed = [
        ...new Set([...state.completed, action.payload - 1]),
      ];
      break;
    case UPDATE_FROM_LANG:
      newState.fromLanguage = action.payload;
      break;
    case UPDATE_TO_LANG:
      newState.toLanguage = action.payload;
      break;
    case UPDATE_USER_FILE:
      newState.userFile = action.payload;
      break;
    case UPDATE_USER_TEXT:
      newState.userText = action.payload;
      break;
    default:
      break;
  }

  return newState;
};
